.bg-image-full {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.bg-image-full::before {
  content: "";
  background: url("/homebg.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  filter: blur(2px);
  z-index: -1;
}
